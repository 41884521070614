import { Attribute, CategoryChildrenName, Product } from '@app/types/catalog';
import { notRequireClinicalDepartmentName } from '@app/utils/constants';
import { formatDate, formatTime, formatWeek } from '@app/utils/format';

/**
 * "YYYY年MM月DD日(曜)"形式または"月曜・火曜・水曜"形式の求人タイトルを取得する。
 * @param product 求人
 * @returns タイトル
 */
export function getProductDayWeekTitle(product: Product | undefined) {
  if (!product) return '';

  const { category, customFields } = product;
  const { name } = category;

  if (
    name === CategoryChildrenName.PRO_DOCTOR_SPOT ||
    name === CategoryChildrenName.PARAMEDICAL_SPOT
  ) {
    return formatDate(customFields?.day);
  }

  if (
    name === CategoryChildrenName.PRO_DOCTOR_REGULAR_PART_TIME ||
    name === CategoryChildrenName.PARAMEDICAL_REGULAR_PART_TIME
  ) {
    return formatWeek(customFields?.repeatWeek);
  }

  return '';
}

/**
 * "YYYY年MM月DD日(曜) hh:mm~hh:mm"形式または"月曜・火曜・水曜 hh:mm~hh:mm"形式の求人タイトルを取得する。
 * @param product 求人
 * @returns タイトル
 */
export function getProductDayWeekTimeTitle(product: Product | undefined) {
  return `${getProductDayWeekTitle(product)} ${formatTime(
    product?.customFields?.startTime
  )}~${formatTime(product?.customFields?.endTime)}`;
}

export function getCalculatedHourlyPay(
  startTime: string,
  endTime: string,
  breakTime: number | null,
  variantsPriceAmount: number | null
) {
  /** 勤務開始時間 */
  const startMinutes = startTime
    .split(':')
    .map((v) => Number(v))
    .reduce((r, v, i) => (i === 0 ? v * 60 : r + v), 0);
  /** 勤務終了時間 */
  const endMinutes = endTime
    .split(':')
    .map((v) => Number(v))
    .reduce((r, v, i) => (i === 0 ? v * 60 : r + v), 0);
  /** 勤務時間（終了時間-開始時間-休憩時間） */
  const workHour = (endMinutes - startMinutes - (breakTime ?? 0)) / 60;
  /** 想定時給 */
  const calculatedHourlyPay = variantsPriceAmount
    ? Math.trunc(variantsPriceAmount / workHour)
    : 0; // 日給の場合は報酬金額÷勤務時間
  return calculatedHourlyPay;
}

export function getCalculatedDairyPay(
  startTime: string,
  endTime: string,
  breakTime: number | null,
  variantsPriceAmount: number | null
) {
  /** 勤務開始時間 */
  const startMinutes = startTime
    .split(':')
    .map((v) => Number(v))
    .reduce((r, v, i) => (i === 0 ? v * 60 : r + v), 0);
  /** 勤務終了時間 */
  const endMinutes = endTime
    .split(':')
    .map((v) => Number(v))
    .reduce((r, v, i) => (i === 0 ? v * 60 : r + v), 0);
  /** 勤務時間（終了時間-開始時間-休憩時間） */
  const workHour = (endMinutes - startMinutes - (breakTime ?? 0)) / 60;
  /** 想定日給 */
  const calculatedDairyPay = variantsPriceAmount
    ? Math.trunc(variantsPriceAmount * workHour)
    : 0; // 時給の場合は報酬金額×勤務時間
  return calculatedDairyPay;
}

export const formatAttributes = (attributes: Attribute[]): Attribute[] => {
  return (
    attributes
      .filter(
        (clinical) =>
          clinical.items[0]?.value !== notRequireClinicalDepartmentName
      )
      ?.reduce((items: Attribute[], item: Attribute) => {
        const foundGroup = items.find(
          (i) => i.items[0]?.groupName === item.items[0]?.groupName
        );
        if (foundGroup) {
          foundGroup.children?.push(item);
        } else {
          items.push({
            ...item,
            children: [item],
          });
        }
        return items;
      }, []) || []
  );
};
