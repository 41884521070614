import {
  format as dateFnsFormat,
  addMonths,
  addDays,
  endOfDay,
  startOfDay,
} from 'date-fns';
import { ja as dateFnsJa } from 'date-fns/locale';
import { z } from 'zod';

export const productFormSchema = z
  .object({
    access: z.string(),
    additionalInformation: z.string(),
    attributesClinical: z
      .string()
      .array()
      .min(1, { message: '募集科目を選択してください' }),
    attributesJob: z
      .string()
      .array()
      .min(1, { message: '仕事種別を選択してください' }),
    breakTime: z.number().nullable(),
    breakTimeNoParticular: z.boolean(),
    categoryEnum: z.enum([
      'spot',
      'regularPartTime',
      'paraSpot',
      'paraRegularPartTime',
    ]),
    commuting: z.enum([
      'UpperLimit',
      'NoUpperLimit',
      'FixedFee',
      'Include',
      'NoFee',
    ]),
    commutingCost: z.number().nullable(),
    count: z
      .number()
      .nullable()
      .refine((v) => v && v > 0, '募集人数を入力してください'),
    days: z.string().array(),
    description: z.string().nonempty('仕事内容を入力してください'),
    endPeriod: z.string().refine((d) => {
      return true;
    }),
    endPeriodDayCount: z.number().nullable(),
    endPeriodStatus: z.enum(['prev', 'daysAgo', 'day']),
    endTime: z.string().nonempty(),
    inputMode: z.enum(['create', 'copy', 'edit']),
    isNotRequireClinicalDepartment: z.boolean(),
    name: z.string().nonempty('求人案件名を入力してください'),
    orderConditions: z.string(),
    overtimeAllowance: z.boolean(),
    possibleOvertime: z.boolean(),
    possibleTimeAdjust: z.boolean(),
    repeatDetail: z.string(),
    repeatWeek: z.number().array(),
    selection: z.string(),
    startPeriod: z.string().nonempty('開始日を入力してください'),
    startTime: z.string().nonempty(),
    status: z.enum(['ACTIVE', 'ARCHIVED', 'DRAFT']),
    variantsDescription: z.string(),
    variantsId: z.string().optional(),
    variantsPriceAmount: z
      .number()
      .nullable()
      .refine((v) => v && v > 0, '金額を入力してください'),
    variantsSku: z.enum(['hour', 'day']),
    workAddress1: z.string(),
    workAddress2: z.string(),
    workCity: z.string(),
    workLocationOther: z.boolean(),
    workPostalCode: z.string(),
    workPrefecture: z.string(),
    workPublicHoliday: z.boolean(),
  })
  .refine(
    (arg) =>
      arg.inputMode === 'edit' ||
      new Date(arg.startPeriod).getTime() >= startOfDay(new Date()).getTime(),
    {
      message: '開始日は現在以降の日付を入力してください',
      path: ['startPeriod'],
    }
  )
  .refine(
    (arg) =>
      new Date(arg.startPeriod).getTime() <=
      endOfDay(addDays(addMonths(new Date(), 6), -1)).getTime(),
    {
      message: '開始日は半年以前の日付を入力してください',
      path: ['startPeriod'],
    }
  )
  .refine((arg) => !(arg.endPeriodStatus === 'day') || arg.endPeriod, {
    message: '終了日を入力してください',
    path: ['endPeriod'],
  })
  .refine(
    (arg) =>
      !(arg.endPeriodStatus === 'day') ||
      new Date(arg.startPeriod).getTime() <= new Date(arg.endPeriod).getTime(),
    {
      message: '終了日は開始日以降の日付を入力してください',
      path: ['endPeriod'],
    }
  )
  .refine(
    (arg) =>
      !(arg.endPeriodStatus === 'day') ||
      new Date(arg.endPeriod).getTime() <=
        endOfDay(addDays(addMonths(new Date(), 6), -1)).getTime(),
    {
      message: '終了日は半年以前の日付を入力してください',
      path: ['endPeriod'],
    }
  )
  .refine(
    (arg) =>
      !(arg.endPeriodStatus === 'daysAgo') ||
      (arg.endPeriodDayCount && arg.endPeriodDayCount > 0),
    {
      message: '勤務日X日前を入力してください',
      path: ['endPeriodDayCount'],
    }
  )
  .refine((arg) => !(arg.categoryEnum === 'spot') || arg.days?.length, {
    message: '募集する勤務日を入力してください',
    path: ['days'],
  })
  .refine(
    (arg) =>
      !(arg.categoryEnum === 'spot') ||
      !arg.days?.some(
        (d) => new Date(d).getTime() < new Date(arg.startPeriod).getTime()
      ),
    {
      message: '募集する勤務日は開始日以降の日付を入力してください',
      path: ['days'],
    }
  )
  .refine(
    (arg) =>
      !(arg.categoryEnum === 'spot') ||
      !arg.days?.some(
        (d) =>
          new Date(d).getTime() >
          endOfDay(addDays(addMonths(new Date(), 6), -1)).getTime()
      ),
    {
      message: '募集する勤務日は半年以前の日付を入力してください',
      path: ['days'],
    }
  )
  .refine(
    (arg) => {
      return !(arg.categoryEnum === 'regularPartTime') || arg.repeatDetail;
    },
    {
      message: '募集曜日の詳細を入力してください',
      path: ['repeatDetail'],
    }
  )
  .refine(
    (arg) => {
      return !(arg.categoryEnum === 'regularPartTime') || arg.repeatWeek.length;
    },
    {
      message: '募集曜日を選択してください',
      path: ['repeatWeek'],
    }
  )
  .refine((arg) => !arg.workLocationOther || arg.workPostalCode, {
    message: '郵便番号を入力してください',
    path: ['workPostalCode'],
  })
  .refine((arg) => !arg.workLocationOther || arg.workPrefecture, {
    message: '都道府県を選択してください',
    path: ['workPrefecture'],
  })
  .refine((arg) => !arg.workLocationOther || arg.workCity, {
    message: '市町村を選択してください',
    path: ['workCity'],
  })
  .refine((arg) => !arg.workLocationOther || arg.workAddress1, {
    message: '町名・番地を入力してください',
    path: ['workAddress1'],
  })
  .refine((arg) => !arg.workLocationOther || arg.access, {
    message: 'アクセス情報を入力してください',
    path: ['access'],
  })
  .refine((arg) => arg.startTime < arg.endTime, {
    message: '終了時間は開始時間より後の時間を入力してください',
    path: ['endTime'],
  })
  .refine(
    (arg) => arg.breakTimeNoParticular || (arg.breakTime && arg.breakTime > 0),
    {
      message: '休憩時間を入力してください',
      path: ['breakTime'],
    }
  )
  .refine(
    (arg) =>
      !(
        arg.commuting === 'UpperLimit' ||
        arg.commuting === 'FixedFee' ||
        arg.commuting === 'Include'
      ) ||
      (arg.commutingCost && arg.commutingCost > 0),
    {
      message: '交通費を入力してください',
      path: ['commutingCost'],
    }
  );

export const nurseProductFormSchema = z
  .object({
    access: z.string(),
    additionalInformation: z.string(),
    attributesClinical: z
      .string()
      .array()
      .min(1, { message: '募集科目を選択してください' }),
    attributesConditions: z
      .string()
      .array()
      .min(1, { message: '条件・特徴してください' }),
    attributesJob: z.string().array(),
    breakTime: z.number().nullable(),
    breakTimeNoParticular: z.boolean(),
    categoryEnum: z.enum([
      'spot',
      'regularPartTime',
      'paraSpot',
      'paraRegularPartTime',
    ]),
    commuting: z.enum([
      'UpperLimit',
      'NoUpperLimit',
      'FixedFee',
      'Include',
      'NoFee',
    ]),
    commutingCost: z.number().nullable(),
    count: z
      .number()
      .nullable()
      .refine((v) => v && v > 0, '募集人数を入力してください'),
    days: z.string().array(),
    description: z.string().nonempty('仕事内容を入力してください'),
    endPeriod: z.string().refine((d) => {
      return true;
    }),
    endPeriodDayCount: z.number().nullable(),
    endPeriodStatus: z.enum(['prev', 'daysAgo', 'day']),
    endTime: z.string().nonempty(),
    inputMode: z.enum(['create', 'copy', 'edit']),
    isNotRequireClinicalDepartment: z.boolean(),
    name: z.string().nonempty('求人案件名を入力してください'),
    orderConditions: z.string(),
    overtimeAllowance: z.boolean(),
    possibleOvertime: z.boolean(),
    possibleTimeAdjust: z.boolean(),
    repeatDetail: z.string(),
    repeatWeek: z.number().array(),
    requireSkill: z
      .string()
      .array()
      .min(1, { message: '必要技能してください' }),
    selection: z.string(),
    startPeriod: z.string().nonempty('開始日を入力してください'),
    startTime: z.string().nonempty(),
    status: z.enum(['ACTIVE', 'ARCHIVED', 'DRAFT']),
    variantsDescription: z.string(),
    variantsId: z.string().optional(),
    variantsPriceAmount: z
      .number()
      .nullable()
      .refine((v) => v && v > 0, '金額を入力してください'),
    variantsSku: z.enum(['hour', 'day']),
    workAddress1: z.string(),
    workAddress2: z.string(),
    workCity: z.string(),
    workLocationOther: z.boolean(),
    workPlaceInfo: z.string().min(1, { message: '職場情報してください' }),
    workPostalCode: z.string(),
    workPrefecture: z.string(),
    workPublicHoliday: z.boolean(),
  })
  .refine(
    (arg) =>
      arg.inputMode === 'edit' ||
      new Date(arg.startPeriod).getTime() >= startOfDay(new Date()).getTime(),
    {
      message: '開始日は現在以降の日付を入力してください',
      path: ['startPeriod'],
    }
  )
  .refine(
    (arg) =>
      new Date(arg.startPeriod).getTime() <=
      endOfDay(addDays(addMonths(new Date(), 6), -1)).getTime(),
    {
      message: '開始日は半年以前の日付を入力してください',
      path: ['startPeriod'],
    }
  )
  .refine((arg) => !(arg.endPeriodStatus === 'day') || arg.endPeriod, {
    message: '終了日を入力してください',
    path: ['endPeriod'],
  })
  .refine(
    (arg) =>
      !(arg.endPeriodStatus === 'day') ||
      new Date(arg.startPeriod).getTime() <= new Date(arg.endPeriod).getTime(),
    {
      message: '終了日は開始日以降の日付を入力してください',
      path: ['endPeriod'],
    }
  )
  .refine(
    (arg) =>
      !(arg.endPeriodStatus === 'day') ||
      new Date(arg.endPeriod).getTime() <=
        endOfDay(addDays(addMonths(new Date(), 6), -1)).getTime(),
    {
      message: '終了日は半年以前の日付を入力してください',
      path: ['endPeriod'],
    }
  )
  .refine(
    (arg) =>
      !(arg.endPeriodStatus === 'daysAgo') ||
      (arg.endPeriodDayCount && arg.endPeriodDayCount > 0),
    {
      message: '勤務日X日前を入力してください',
      path: ['endPeriodDayCount'],
    }
  )
  .refine((arg) => !(arg.categoryEnum === 'spot') || arg.days?.length, {
    message: '募集する勤務日を入力してください',
    path: ['days'],
  })
  .refine(
    (arg) =>
      !(arg.categoryEnum === 'spot') ||
      !arg.days?.some(
        (d) => new Date(d).getTime() < new Date(arg.startPeriod).getTime()
      ),
    {
      message: '募集する勤務日は開始日以降の日付を入力してください',
      path: ['days'],
    }
  )
  .refine(
    (arg) =>
      !(arg.categoryEnum === 'spot') ||
      !arg.days?.some(
        (d) =>
          new Date(d).getTime() >
          endOfDay(addDays(addMonths(new Date(), 6), -1)).getTime()
      ),
    {
      message: '募集する勤務日は半年以前の日付を入力してください',
      path: ['days'],
    }
  )
  .refine(
    (arg) => {
      return !(arg.categoryEnum === 'regularPartTime') || arg.repeatDetail;
    },
    {
      message: '募集曜日の詳細を入力してください',
      path: ['repeatDetail'],
    }
  )
  .refine(
    (arg) => {
      return !(arg.categoryEnum === 'regularPartTime') || arg.repeatWeek.length;
    },
    {
      message: '募集曜日を選択してください',
      path: ['repeatWeek'],
    }
  )
  .refine((arg) => !arg.workLocationOther || arg.workPostalCode, {
    message: '郵便番号を入力してください',
    path: ['workPostalCode'],
  })
  .refine((arg) => !arg.workLocationOther || arg.workPrefecture, {
    message: '都道府県を選択してください',
    path: ['workPrefecture'],
  })
  .refine((arg) => !arg.workLocationOther || arg.workCity, {
    message: '市町村を選択してください',
    path: ['workCity'],
  })
  .refine((arg) => !arg.workLocationOther || arg.workAddress1, {
    message: '町名・番地を入力してください',
    path: ['workAddress1'],
  })
  .refine((arg) => !arg.workLocationOther || arg.access, {
    message: 'アクセス情報を入力してください',
    path: ['access'],
  })
  .refine((arg) => arg.startTime < arg.endTime, {
    message: '終了時間は開始時間より後の時間を入力してください',
    path: ['endTime'],
  })
  .refine(
    (arg) => arg.breakTimeNoParticular || (arg.breakTime && arg.breakTime > 0),
    {
      message: '休憩時間を入力してください',
      path: ['breakTime'],
    }
  )
  .refine(
    (arg) =>
      !(
        arg.commuting === 'UpperLimit' ||
        arg.commuting === 'FixedFee' ||
        arg.commuting === 'Include'
      ) ||
      (arg.commutingCost && arg.commutingCost > 0),
    {
      message: '交通費を入力してください',
      path: ['commutingCost'],
    }
  );

export type ProductForm = z.infer<typeof productFormSchema>;
export type NurseProductForm = z.infer<typeof nurseProductFormSchema>;

export const defaultProductForm: ProductForm = {
  access: '',
  additionalInformation: '',
  attributesClinical: [],
  attributesJob: [],
  breakTime: 60,
  breakTimeNoParticular: false,
  categoryEnum: 'spot',
  commuting: 'UpperLimit',
  commutingCost: null,
  count: 1,
  days: [],
  description: '',
  endPeriod: dateFnsFormat(addDays(addMonths(new Date(), 6), -1), 'yyyy/MM/dd'),
  endPeriodDayCount: null,
  endPeriodStatus: 'prev',
  endTime: '18:00',
  inputMode: 'create',
  isNotRequireClinicalDepartment: false,
  name: '',
  orderConditions: '',
  overtimeAllowance: false,
  possibleOvertime: false,
  possibleTimeAdjust: false,
  repeatDetail: '',
  repeatWeek: [],
  selection: '',
  startPeriod: dateFnsFormat(new Date(), 'yyyy/MM/dd'),
  startTime: '08:00',
  status: 'ACTIVE',
  variantsDescription: '',
  variantsId: undefined,
  variantsPriceAmount: null,
  variantsSku: 'day',
  workAddress1: '',
  workAddress2: '',
  workCity: '',
  workLocationOther: false,
  workPostalCode: '',
  workPrefecture: '',
  workPublicHoliday: false,
};

export const productBulkFormSchema = z
  .object({
    access: z.string(),
    additionalInformation: z.string(),
    attributesClinical: z.string().array(),
    attributesJob: z.string().array(),
    breakTime: z.number().nullable(),
    breakTimeNoParticular: z.boolean(),
    checkAdditionalInformation: z.boolean(),
    checkAll: z.undefined(), // NOTE:全体チェック用に定義
    checkAttributesClinical: z.boolean(),
    checkAttributesJob: z.boolean(),
    checkBreakTime: z.boolean(),
    checkCommuting: z.boolean(),
    checkCount: z.boolean(),
    checkDescription: z.boolean(),
    checkOrderConditions: z.boolean(),
    checkPeriod: z.boolean(),
    checkPrice: z.boolean(),
    checkSelection: z.boolean(),
    checkStatus: z.boolean(),
    checkTime: z.boolean(),
    checkVariantsDescription: z.boolean(),
    checkWorkLocation: z.boolean(),
    commuting: z.enum([
      'UpperLimit',
      'NoUpperLimit',
      'FixedFee',
      'Include',
      'NoFee',
      '',
    ]),
    commutingCost: z.number().nullable(),
    count: z.number().nullable(),
    day: z.date().optional(), // NOTE:チェック用
    description: z.string(),
    endPeriod: z.string(),
    endTime: z.string(),
    isNotRequireClinicalDepartment: z.boolean(),
    orderConditions: z.string(),
    overtimeAllowance: z.boolean(),
    possibleOvertime: z.boolean(),
    possibleTimeAdjust: z.boolean(),
    selection: z.string(),
    startPeriod: z.string(),
    startTime: z.string(),
    status: z.enum(['ACTIVE', 'ARCHIVED', 'DRAFT', '']),
    variantsDescription: z.string(),
    variantsPriceAmount: z.number().nullable(),
    variantsSku: z.enum(['hour', 'day', '']),
    workAddress1: z.string(),
    workAddress2: z.string(),
    workCity: z.string(),
    workLocationOther: z.boolean(),
    workPostalCode: z.string(),
    workPrefecture: z.string(),
  })
  .refine(
    (arg) =>
      arg.checkAdditionalInformation ||
      arg.checkAttributesClinical ||
      arg.checkAttributesJob ||
      arg.checkBreakTime ||
      arg.checkCommuting ||
      arg.checkCount ||
      arg.checkDescription ||
      arg.checkOrderConditions ||
      arg.checkPeriod ||
      arg.checkPrice ||
      arg.checkSelection ||
      arg.checkStatus ||
      arg.checkTime ||
      arg.checkVariantsDescription ||
      arg.checkWorkLocation,
    {
      message: 'いずれかの項目にチェックを入れてください',
      path: ['checkAll'],
    }
  )
  .refine((arg) => !arg.checkStatus || arg.status, {
    message: '掲載ステータスを入力してください',
    path: ['status'],
  })
  .refine((arg) => !arg.checkPeriod || arg.startPeriod, {
    message: '開始日を入力してください',
    path: ['startPeriod'],
  })
  .refine(
    (arg) =>
      !arg.checkPeriod ||
      new Date(arg.startPeriod).getTime() >= startOfDay(new Date()).getTime(),
    {
      message: '開始日は現在以降の日付を入力してください',
      path: ['startPeriod'],
    }
  )
  .refine(
    (arg) =>
      !arg.checkPeriod ||
      new Date(arg.startPeriod).getTime() <=
        endOfDay(addDays(addMonths(new Date(), 6), -1)).getTime(),
    {
      message: '開始日は半年以前の日付を入力してください',
      path: ['startPeriod'],
    }
  )
  .refine(
    (arg) => {
      return (
        !arg.checkPeriod ||
        !arg.day ||
        new Date(arg.startPeriod).getTime() <= arg.day.getTime()
      );
    },
    (arg) => {
      return {
        message: `開始日は${
          arg.day
            ? dateFnsFormat(arg.day, 'yyyy年MM月dd日', { locale: dateFnsJa })
            : ''
        }以前の日付を入力してください`,
        path: ['startPeriod'],
      };
    }
  )
  .refine((arg) => !arg.checkPeriod || arg.endPeriod, {
    message: '終了日を入力してください',
    path: ['endPeriod'],
  })
  .refine(
    (arg) =>
      !arg.checkPeriod ||
      new Date(arg.endPeriod).getTime() <=
        endOfDay(addDays(addMonths(new Date(), 6), -1)).getTime(),
    {
      message: '終了日は半年以前の日付を入力してください',
      path: ['endPeriod'],
    }
  )
  .refine(
    (arg) =>
      !arg.checkPeriod ||
      new Date(arg.startPeriod).getTime() <= new Date(arg.endPeriod).getTime(),
    {
      message: '終了日は開始日以降の日付を入力してください',
      path: ['endPeriod'],
    }
  )
  .refine(
    (arg) =>
      !arg.checkWorkLocation || !arg.workLocationOther || arg.workPostalCode,
    {
      message: '郵便番号を入力してください',
      path: ['workPostalCode'],
    }
  )
  .refine(
    (arg) =>
      !arg.checkWorkLocation || !arg.workLocationOther || arg.workPrefecture,
    {
      message: '都道府県を選択してください',
      path: ['workPrefecture'],
    }
  )
  .refine(
    (arg) => !arg.checkWorkLocation || !arg.workLocationOther || arg.workCity,
    {
      message: '市町村を選択してください',
      path: ['workCity'],
    }
  )
  .refine(
    (arg) =>
      !arg.checkWorkLocation || !arg.workLocationOther || arg.workAddress1,
    {
      message: '町名・番地を入力してください',
      path: ['workAddress1'],
    }
  )
  .refine(
    (arg) => !arg.checkWorkLocation || !arg.workLocationOther || arg.access,
    {
      message: 'アクセス情報を入力してください',
      path: ['access'],
    }
  )
  .refine(
    (arg) => !arg.checkAttributesClinical || arg.attributesClinical.length,
    {
      message: '募集科目を選択してください',
      path: ['attributesClinical'],
    }
  )
  .refine((arg) => !arg.checkAttributesJob || arg.attributesJob.length, {
    message: '仕事種別を選択してください',
    path: ['attributesJob'],
  })
  .refine((arg) => !arg.checkDescription || arg.description, {
    message: '仕事内容を入力してください',
    path: ['description'],
  })
  .refine((arg) => !arg.checkTime || arg.startTime, {
    message: '開始時間を入力してください',
    path: ['startTime'],
  })
  .refine((arg) => !arg.checkTime || arg.endTime, {
    message: '終了時間を入力してください',
    path: ['endTime'],
  })
  .refine((arg) => !arg.checkTime || arg.startTime < arg.endTime, {
    message: '終了時間は開始時間より後の時間を入力してください',
    path: ['endTime'],
  })
  .refine(
    (arg) => !arg.checkBreakTime || arg.breakTimeNoParticular || arg.breakTime,
    {
      message: '休憩時間を入力してください',
      path: ['breakTime'],
    }
  )
  .refine((arg) => !arg.checkPrice || arg.variantsSku, {
    message: '給与を選択してください',
    path: ['variantsSku'],
  })
  .refine((arg) => !arg.checkPrice || arg.variantsPriceAmount, {
    message: '給与を入力してください',
    path: ['variantsPriceAmount'],
  })
  .refine((arg) => !arg.checkCommuting || arg.commuting, {
    message: '交通費を選択してください',
    path: ['commuting'],
  })
  .refine(
    (arg) =>
      !arg.checkCommuting ||
      !(
        arg.commuting === 'UpperLimit' ||
        arg.commuting === 'FixedFee' ||
        arg.commuting === 'Include'
      ) ||
      arg.commutingCost,
    {
      message: '交通費を入力してください',
      path: ['commutingCost'],
    }
  )
  .refine((arg) => !arg.checkCount || arg.count, {
    message: '募集人数を選択してください',
    path: ['count'],
  });

export const nurseProductBulkFormSchema = z
  .object({
    access: z.string(),
    additionalInformation: z.string(),
    attributesClinical: z.string().array(),
    attributesConditions: z.string().array(),
    attributesJob: z.string().array(),
    attributesRequireSkills: z.string().array(),
    breakTime: z.number().nullable(),
    breakTimeNoParticular: z.boolean(),
    checkAdditionalInformation: z.boolean(),
    checkAll: z.undefined(),
    // NOTE:全体チェック用に定義
    checkAttributesClinical: z.boolean(),
    checkAttributesConditions: z.boolean(),
    checkAttributesJob: z.boolean(),
    checkAttributesRequireSkills: z.boolean(),
    checkBreakTime: z.boolean(),
    checkCommuting: z.boolean(),
    checkCount: z.boolean(),
    checkDescription: z.boolean(),
    checkOrderConditions: z.boolean(),
    checkPeriod: z.boolean(),
    checkPrice: z.boolean(),
    checkSelection: z.boolean(),
    checkStatus: z.boolean(),
    checkTime: z.boolean(),
    checkVariantsDescription: z.boolean(),
    checkWorkLocation: z.boolean(),
    checkWorkPlaceInfo: z.boolean(),
    commuting: z.enum([
      'UpperLimit',
      'NoUpperLimit',
      'FixedFee',
      'Include',
      'NoFee',
      '',
    ]),
    commutingCost: z.number().nullable(),
    count: z.number().nullable(),
    day: z.date().optional(),
    // NOTE:チェック用
    description: z.string(),

    endPeriod: z.string(),
    endTime: z.string(),
    isNotRequireClinicalDepartment: z.boolean(),
    orderConditions: z.string(),
    overtimeAllowance: z.boolean(),
    possibleOvertime: z.boolean(),
    possibleTimeAdjust: z.boolean(),
    selection: z.string(),
    startPeriod: z.string(),
    startTime: z.string(),
    status: z.enum(['ACTIVE', 'ARCHIVED', 'DRAFT', '']),
    variantsDescription: z.string(),
    variantsPriceAmount: z.number().nullable(),
    variantsSku: z.enum(['hour', 'day', '']),
    workAddress1: z.string(),
    workAddress2: z.string(),
    workCity: z.string(),
    workLocationOther: z.boolean(),
    workPlaceInfo: z.string(),
    workPostalCode: z.string(),
    workPrefecture: z.string(),
  })
  .refine(
    (arg) =>
      arg.checkAdditionalInformation ||
      arg.checkAttributesClinical ||
      arg.checkAttributesConditions ||
      arg.checkAttributesRequireSkills ||
      arg.checkAttributesJob ||
      arg.checkBreakTime ||
      arg.checkCommuting ||
      arg.checkCount ||
      arg.checkDescription ||
      arg.checkOrderConditions ||
      arg.checkPeriod ||
      arg.checkPrice ||
      arg.checkSelection ||
      arg.checkStatus ||
      arg.checkTime ||
      arg.checkVariantsDescription ||
      arg.checkWorkLocation ||
      arg.checkWorkPlaceInfo,
    {
      message: 'いずれかの項目にチェックを入れてください',
      path: ['checkAll'],
    }
  )
  .refine((arg) => !arg.checkStatus || arg.status, {
    message: '掲載ステータスを入力してください',
    path: ['status'],
  })
  .refine((arg) => !arg.checkPeriod || arg.startPeriod, {
    message: '開始日を入力してください',
    path: ['startPeriod'],
  })
  .refine(
    (arg) =>
      !arg.checkPeriod ||
      new Date(arg.startPeriod).getTime() >= startOfDay(new Date()).getTime(),
    {
      message: '開始日は現在以降の日付を入力してください',
      path: ['startPeriod'],
    }
  )
  .refine(
    (arg) =>
      !arg.checkPeriod ||
      new Date(arg.startPeriod).getTime() <=
        endOfDay(addDays(addMonths(new Date(), 6), -1)).getTime(),
    {
      message: '開始日は半年以前の日付を入力してください',
      path: ['startPeriod'],
    }
  )
  .refine(
    (arg) => {
      return (
        !arg.checkPeriod ||
        !arg.day ||
        new Date(arg.startPeriod).getTime() <= arg.day.getTime()
      );
    },
    (arg) => {
      return {
        message: `開始日は${
          arg.day
            ? dateFnsFormat(arg.day, 'yyyy年MM月dd日', { locale: dateFnsJa })
            : ''
        }以前の日付を入力してください`,
        path: ['startPeriod'],
      };
    }
  )
  .refine((arg) => !arg.checkPeriod || arg.endPeriod, {
    message: '終了日を入力してください',
    path: ['endPeriod'],
  })
  .refine(
    (arg) =>
      !arg.checkPeriod ||
      new Date(arg.endPeriod).getTime() <=
        endOfDay(addDays(addMonths(new Date(), 6), -1)).getTime(),
    {
      message: '終了日は半年以前の日付を入力してください',
      path: ['endPeriod'],
    }
  )
  .refine(
    (arg) =>
      !arg.checkPeriod ||
      new Date(arg.startPeriod).getTime() <= new Date(arg.endPeriod).getTime(),
    {
      message: '終了日は開始日以降の日付を入力してください',
      path: ['endPeriod'],
    }
  )
  .refine(
    (arg) =>
      !arg.checkWorkLocation || !arg.workLocationOther || arg.workPostalCode,
    {
      message: '郵便番号を入力してください',
      path: ['workPostalCode'],
    }
  )
  .refine(
    (arg) =>
      !arg.checkWorkLocation || !arg.workLocationOther || arg.workPrefecture,
    {
      message: '都道府県を選択してください',
      path: ['workPrefecture'],
    }
  )
  .refine(
    (arg) => !arg.checkWorkLocation || !arg.workLocationOther || arg.workCity,
    {
      message: '市町村を選択してください',
      path: ['workCity'],
    }
  )
  .refine(
    (arg) =>
      !arg.checkWorkLocation || !arg.workLocationOther || arg.workAddress1,
    {
      message: '町名・番地を入力してください',
      path: ['workAddress1'],
    }
  )
  .refine(
    (arg) => !arg.checkWorkLocation || !arg.workLocationOther || arg.access,
    {
      message: 'アクセス情報を入力してください',
      path: ['access'],
    }
  )
  .refine(
    (arg) => !arg.checkAttributesClinical || arg.attributesClinical.length,
    {
      message: '募集科目を選択してください',
      path: ['attributesClinical'],
    }
  )
  .refine(
    (arg) => !arg.checkAttributesConditions || arg.attributesConditions.length,
    {
      message: '条件・特徴してください',
      path: ['attributesConditions'],
    }
  )
  .refine(
    (arg) =>
      !arg.checkAttributesRequireSkills || arg.attributesRequireSkills.length,
    {
      message: '必要技能してください',
      path: ['attributesRequireSkills'],
    }
  )
  .refine((arg) => !arg.checkDescription || arg.description, {
    message: '仕事内容を入力してください',
    path: ['description'],
  })
  .refine((arg) => !arg.checkWorkPlaceInfo || arg.workPlaceInfo, {
    message: '職場情報してください',
    path: ['workPlaceInfo'],
  })
  .refine((arg) => !arg.checkTime || arg.startTime, {
    message: '開始時間を入力してください',
    path: ['startTime'],
  })
  .refine((arg) => !arg.checkTime || arg.endTime, {
    message: '終了時間を入力してください',
    path: ['endTime'],
  })
  .refine((arg) => !arg.checkTime || arg.startTime < arg.endTime, {
    message: '終了時間は開始時間より後の時間を入力してください',
    path: ['endTime'],
  })
  .refine(
    (arg) => !arg.checkBreakTime || arg.breakTimeNoParticular || arg.breakTime,
    {
      message: '休憩時間を入力してください',
      path: ['breakTime'],
    }
  )
  .refine((arg) => !arg.checkPrice || arg.variantsSku, {
    message: '給与を選択してください',
    path: ['variantsSku'],
  })
  .refine((arg) => !arg.checkPrice || arg.variantsPriceAmount, {
    message: '給与を入力してください',
    path: ['variantsPriceAmount'],
  })
  .refine((arg) => !arg.checkCommuting || arg.commuting, {
    message: '交通費を選択してください',
    path: ['commuting'],
  })
  .refine(
    (arg) =>
      !arg.checkCommuting ||
      !(
        arg.commuting === 'UpperLimit' ||
        arg.commuting === 'FixedFee' ||
        arg.commuting === 'Include'
      ) ||
      arg.commutingCost,
    {
      message: '交通費を入力してください',
      path: ['commutingCost'],
    }
  )
  .refine((arg) => !arg.checkCount || arg.count, {
    message: '募集人数を選択してください',
    path: ['count'],
  });

export type ProductBulkForm = z.infer<typeof productBulkFormSchema>;
export type NurseProductBulkForm = z.infer<typeof nurseProductBulkFormSchema>;

export const defaultProductBulkForm: ProductBulkForm = {
  access: '',
  additionalInformation: '',
  attributesClinical: [],
  attributesJob: [],
  breakTime: 60,
  breakTimeNoParticular: false,
  checkAdditionalInformation: false,
  checkAttributesClinical: false,
  checkAttributesJob: false,
  checkBreakTime: false,
  checkCommuting: false,
  checkCount: false,
  checkDescription: false,
  checkOrderConditions: false,
  checkPeriod: false,
  checkPrice: false,
  checkSelection: false,
  checkStatus: false,
  checkTime: false,
  checkVariantsDescription: false,
  checkWorkLocation: false,
  commuting: 'UpperLimit',
  commutingCost: null,
  count: 1,
  description: '',
  endPeriod: dateFnsFormat(addDays(addMonths(new Date(), 6), -1), 'yyyy/MM/dd'),
  endTime: '18:00',
  isNotRequireClinicalDepartment: false,
  orderConditions: '',
  overtimeAllowance: false,
  possibleOvertime: false,
  possibleTimeAdjust: false,
  selection: '',
  startPeriod: dateFnsFormat(new Date(), 'yyyy/MM/dd'),
  startTime: '00:00',
  status: '',
  variantsDescription: '',
  variantsPriceAmount: null,
  variantsSku: 'day',
  workAddress1: '',
  workAddress2: '',
  workCity: '',
  workLocationOther: false,
  workPostalCode: '',
  workPrefecture: '',
};

export const defaultNurseProductBulkForm: NurseProductBulkForm = {
  access: '',
  additionalInformation: '',
  attributesClinical: [],
  attributesConditions: [],
  attributesJob: [],
  attributesRequireSkills: [],
  breakTime: 60,
  breakTimeNoParticular: false,
  checkAdditionalInformation: false,
  checkAttributesClinical: false,
  checkAttributesConditions: false,
  checkAttributesJob: false,
  checkAttributesRequireSkills: false,
  checkBreakTime: false,
  checkCommuting: false,
  checkCount: false,
  checkDescription: false,
  checkOrderConditions: false,
  checkPeriod: false,
  checkPrice: false,
  checkSelection: false,
  checkStatus: false,
  checkTime: false,
  checkVariantsDescription: false,
  checkWorkLocation: false,
  checkWorkPlaceInfo: false,
  commuting: 'UpperLimit',
  commutingCost: null,
  count: 1,
  description: '',
  endPeriod: dateFnsFormat(addDays(addMonths(new Date(), 6), -1), 'yyyy/MM/dd'),
  endTime: '18:00',
  isNotRequireClinicalDepartment: false,
  orderConditions: '',
  overtimeAllowance: false,
  possibleOvertime: false,
  possibleTimeAdjust: false,
  selection: '',
  startPeriod: dateFnsFormat(new Date(), 'yyyy/MM/dd'),
  startTime: '00:00',
  status: '',
  variantsDescription: '',
  variantsPriceAmount: null,
  variantsSku: 'day',
  workAddress1: '',
  workAddress2: '',
  workCity: '',
  workLocationOther: false,
  workPlaceInfo: '',
  workPostalCode: '',
  workPrefecture: '',
};
