import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  fetchCategories,
  getAttributes,
  getProducts,
  getLocationById,
} from '@app/adapter/catalog-service';
import { AttributeSelectItem } from '@app/components/Shared/AttributeSelect';
import { snackbarOpenState, snackbarTextState } from '@app/domain/app';
import { organizationSelector } from '@app/domain/organization';
import {
  Attribute,
  AttributeGroupName,
  Category,
  CategoryParentName,
  Product,
  ProductLocation,
} from '@app/types/catalog';
import { notRequireClinicalDepartmentName } from '@app/utils/constants';

/**
 * 求人IDから求人リストを取得するフック
 * 求人IDが変わる毎に読み込む求人が増加する
 * ※一度読み込まれた求人はストックし続ける
 * ※新たに読み込める求人IDは100件まで
 * @returns 求人リスト
 */
export function useProductsAdd(
  productIds: string[] | undefined,
  isAdmin = false
) {
  const organizationState = useRecoilValue(organizationSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [state, setState] = useState(undefined as Product[] | undefined);
  const load = useCallback(async () => {
    try {
      if (!productIds?.length) {
        return;
      }
      // NOTE:未取得の求人IDのみ取得
      const productIds2 = productIds.filter(
        (id) => !state?.some((p) => p.id === id)
      );
      if (!productIds2.length) {
        return;
      }
      const result = await getProducts(
        isAdmin ? undefined : organizationState.id,
        {
          ids: productIds2,
          isAdmin,
          pageNumber: 0,
          pageSize: productIds2.length,
        }
      );
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const productIds3 = productIds2.filter(
        (id) => !result.data.value?.some((p) => p.id === id)
      );
      // 他の医療機関の求人を取得する
      const result2 =
        isAdmin || !productIds3.length
          ? undefined
          : await getProducts(undefined, {
              ids: productIds3,
              isAdmin,
              pageNumber: 0,
              pageSize: productIds2.length,
            });
      if (result2 && result2.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      if (!result.data.value.length && !result2?.data.value.length) {
        return;
      }
      setState((prev) => [
        ...(prev ?? []),
        ...result.data.value,
        ...(result2?.data.value ?? []),
      ]);
    } catch (error) {
      setSnackbarText(
        `求人の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [
    productIds,
    isAdmin,
    state,
    organizationState,
    setSnackbarOpen,
    setSnackbarText,
  ]);

  useEffect(() => {
    void load();
  }, [load]);
  return state;
}

/**
 * 診療科目リストを取得するフック
 * @returns 診療科目リスト
 */
export function useAttributesClinicalDepartment() {
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [state, setState] = useState(undefined as Attribute[] | undefined);
  const load = useCallback(async () => {
    if (state) {
      return;
    }
    try {
      const result = await getAttributes({
        filter: {
          groupName: ['clinicalDepartment'],
        },
        pageNumber: 0,
        pageSize: 100,
      });
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      setState(result.data.value ?? []);
    } catch (error) {
      setState([]);
      setSnackbarText(
        `診療科目の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [state, setState, setSnackbarOpen, setSnackbarText]);
  useEffect(() => {
    void load();
  }, [load]);
  return state;
}

/**
 * 仕事種別リストを取得するフック
 * @returns 仕事種別リスト
 */
export function useAttributesJobType() {
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [state, setState] = useState(undefined as Attribute[] | undefined);
  const load = useCallback(async () => {
    if (state) {
      return;
    }
    try {
      const result = await getAttributes({
        filter: {
          groupName: ['jobType'],
        },
        pageNumber: 0,
        pageSize: 100,
      });
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      setState(result.data.value ?? []);
    } catch (error) {
      setState([]);
      setSnackbarText(
        `仕事種別の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [state, setState, setSnackbarOpen, setSnackbarText]);
  useEffect(() => {
    void load();
  }, [load]);
  return state;
}

/**
 * 案件区分リストを取得するフック
 * @returns 案件区分リスト
 */
export function useProductCategories() {
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [state, setState] = useState(undefined as Category[] | undefined);
  const load = useCallback(async () => {
    if (state) {
      return;
    }
    try {
      const result = await fetchCategories();
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const category = result.data.value.find(
        (c) => c.name === CategoryParentName.PRO_DOCTOR
      );
      setState(category?.children ?? []);
    } catch (error) {
      setState([]);
      setSnackbarText(
        `案件区分の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [state, setState, setSnackbarOpen, setSnackbarText]);
  useEffect(() => {
    void load();
  }, [load]);
  return state;
}

/**
 * 住所を取得するフック
 * @returns 住所
 */
export function useProductLocation(locationId: string | undefined) {
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [state, setState] = useState(undefined as ProductLocation | undefined);
  const load = useCallback(async () => {
    try {
      if (!locationId) {
        return;
      }
      const result = await getLocationById(locationId);
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      setState(result.data);
    } catch (error) {
      setSnackbarText(
        `住所の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [locationId, setSnackbarOpen, setSnackbarText]);

  useEffect(() => {
    void load();
  }, [load]);
  return state;
}

export function useNurseAttributes() {
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [clinicalDepartmentList, setClinicalDepartmentList] = useState<
    AttributeSelectItem[]
  >([]);

  const [notRequireDepartmentId, setNotRequireDepartmentId] = useState<
    string[]
  >([]);
  // 仕事種別リストの状態
  const [jobTypeList, setJobTypeList] = useState<Attribute[]>([]);

  const [requireSkillList, setRequireSkillList] = useState<
    AttributeSelectItem[]
  >([]);

  const [conditionsList, setConditionsList] = useState<Attribute[]>([]);
  const load = useCallback(async () => {
    let allAttributes: Attribute[] = [];
    let nextLink = '';

    try {
      do {
        const result = await getAttributes({
          filter: {
            groupName: [
              AttributeGroupName.PARA_CLINICAL_DEPARTMENT,
              AttributeGroupName.PARA_JOB_TYPE,
              AttributeGroupName.PARA_REQUIRE_SKILL,
              AttributeGroupName.PARA_CONDITIONS,
            ],
          },
          nextLink,
          pageNumber: 0,
          pageSize: 100,
        });
        allAttributes = [...allAttributes, ...result.data.value];
        nextLink = result.data['@nextLink'] || '';
      } while (nextLink);
      const clinicalDepartmentList: Attribute[] = allAttributes.filter(
        (item) => item.groupName === AttributeGroupName.PARA_CLINICAL_DEPARTMENT
      );
      const notRequireDeptId = clinicalDepartmentList.find(
        (a) => a.items[0].value === notRequireClinicalDepartmentName
      )?.id;
      setNotRequireDepartmentId(notRequireDeptId ? [notRequireDeptId] : []);
      const clinicalSelectList: AttributeSelectItem[] =
        clinicalDepartmentList.map((a) => ({
          group: a.items?.[0].groupName ?? '',
          id: a.id,
          name: a.items?.[0].value ?? '',
        }));
      const jobList: Attribute[] = allAttributes.filter(
        (item) => item.groupName === AttributeGroupName.PARA_JOB_TYPE
      );

      const requireSkillList: AttributeSelectItem[] = allAttributes
        .filter(
          (item) => item.groupName === AttributeGroupName.PARA_REQUIRE_SKILL
        )
        .map((a) => ({
          id: a.id,
          name: a.name ?? '',
        }));
      const conditionsList: Attribute[] = allAttributes.filter(
        (item) => item.groupName === AttributeGroupName.PARA_CONDITIONS
      );
      setClinicalDepartmentList(clinicalSelectList);
      setJobTypeList(jobList);
      setRequireSkillList(requireSkillList);
      setConditionsList(conditionsList);
      return [clinicalSelectList, jobList, requireSkillList, conditionsList];
    } catch (error) {
      setSnackbarText(
        `診療科目または仕事種別の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
      return [[], []] as AttributeSelectItem[][];
    }
  }, [setSnackbarOpen, setSnackbarText]);
  useEffect(() => {
    void load();
  }, [load]);
  return {
    clinicalDepartmentList,
    conditionsList,
    jobTypeList,
    notRequireDepartmentId,
    requireSkillList,
  };
}
