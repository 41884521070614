import { startOfMonth, endOfMonth } from 'date-fns';

import {
  Product,
  ProductInput,
  ProductCustomFields,
  ProductFeature,
  ProductStatus,
  SupportedProductPublicationStatus,
} from '@app/types/catalog';
import { MessageStatus, MessageTarget } from '@app/types/notifications';
import { OrderStatus } from '@app/types/order';

export const STORAGE_KEYS = {
  /*
    localStorage, sessionStorage を使う場合はここに key を追加
    重複しないように一元管理
  */

  ACCESS_TOKEN: 'accessToken',
  FINGERPRINT: 'fingerprint',
  REFRESH_TOKEN: 'refreshToken',
  USER_ID: 'userId',
};

export const prefectureData = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
];

export const defaultFeature: Array<ProductFeature> = [
  {
    name: '女性医療機関',
    value: '',
  },
  {
    name: '成果報酬型',
    value: '',
  },
  {
    name: '無料カウンセリング有り',
    value: '',
  },
  {
    name: '相談実績多数',
    value: '',
  },
  {
    name: '拠点多数',
    value: '',
  },
  {
    name: '認定資格有',
    value: '',
  },
];

export const localizedOrderStatus = {
  ACCEPTED: '受ける',
  CANCELED: '取消',
  CLOSED: '完了',
  PENDING: '未決',
  PROCESSING: '対応',
};

export const defaultLineItem = {
  price: {
    amount: 0,
    taxable: true,
  },
  productId: '',
  productName: '',
  quantity: 0,
  sku: '',
  variant: '',
  variantTitle: '',
};

export const defaultOrderStatusFilters = [
  OrderStatus.ACCEPTED,
  OrderStatus.CLOSED,
  OrderStatus.PENDING,
  OrderStatus.PROCESSING,
  OrderStatus.CANCELED,
];

export const defaultProductStatus = [
  ProductStatus.DRAFT,
  ProductStatus.ACTIVE,
  ProductStatus.ARCHIVED,
];

export const defaultProductCustomFields: ProductCustomFields = {
  access: '',
  breakTime: 0,
  breakTimeNoParticular: false,
  calculatedDairyPay: 0,
  calculatedHourlyPay: 0,
  commuting: '',
  commutingCost: 0,
  count: 1,
  day: '',
  endTime: '',
  incentive: '',
  orderConditions: '',
  organizationName: '',
  overWorkConditions: [],
  repeatDetail: '',
  repeatWeek: [],
  selection: '',
  startTime: '',
  workAddress1: '',
  workAddress2: '',
  workPostalCode: '',
  workPublicHoliday: false,
};

export const defaultProductValue: Product = {
  additionalInformation: '',
  attributes: [],
  category: {
    children: [],
    description: '',
    id: '',
    name: '',
    numberOfProducts: 0,
    parent: null,
    status: 'PENDING',
  },
  categoryId: '',
  customFields: { ...defaultProductCustomFields },
  description: '',
  dimensions: '',
  features: [],
  id: '',
  images: [],
  locations: [],
  name: '',
  organization: {},
  organizationId: '',
  publication: {
    publishedAt: '',
    publishedBy: '',
    since: startOfMonth(new Date()).toISOString(),
    status: 'DRAFT' as SupportedProductPublicationStatus,
    until: endOfMonth(new Date()).toISOString(),
  },
  tags: [],
  variantNote: '',
  variants: [],
};

export const defaultProductInput: ProductInput = {
  additionalInformation: '',
  attributes: [],
  categoryId: '',
  customFields: { ...defaultProductCustomFields },
  description: '',
  dimensions: '',
  features: [],
  imageIds: [],
  locationIds: [],
  name: '',
  publication: {
    since: startOfMonth(new Date()).toISOString(),
    status: 'DRAFT' as SupportedProductPublicationStatus,
    until: endOfMonth(new Date()).toISOString(),
  },
  tags: [],
  variantNote: '',
  variants: [],
};

export const productStatusList = [
  {
    id: 'ACTIVE',
    name: '掲載中',
  },
  {
    id: 'ARCHIVED',
    name: '終了',
  },
  {
    id: 'DRAFT',
    name: '下書き',
  },
];

export const productCategoryList = [
  {
    id: 'spot',
    name: 'スポット',
  },
  {
    id: 'regularPartTime',
    name: '定期非常勤',
  },
];

export const nurseProductCategoryList = [
  {
    id: 'spot',
    label: 'スポット',
    name: 'パラメディカル＞スポット',
  },
  {
    id: 'regularPartTime',
    label: '定期非常勤',
    name: 'パラメディカル＞定期非常勤',
  },
];

export const productEndPeriodStatusList = [
  {
    id: 'prev',
    name: '勤務日前日',
  },
  {
    id: 'daysAgo',
    name: '勤務日X日前',
  },
  {
    id: 'day',
    name: '日付指定',
  },
];

export const productVariantsSkuList = [
  {
    id: 'hour',
    name: '時給',
  },
  {
    id: 'day',
    name: '日給',
  },
];

export const productCommutingList = [
  {
    id: 'UpperLimit',
    name: '別途支給（上限金額）',
  },
  {
    id: 'NoUpperLimit',
    name: '別途支給（上限なし）',
  },
  {
    id: 'FixedFee',
    name: '別途支給（定額）',
  },
  {
    id: 'Include',
    name: '交通費込み（所定金額）',
  },
  {
    id: 'NoFee',
    name: 'なし',
  },
];

export const productSelectionList = [
  '',
  '対面面接必須',
  'オンライン面接可',
  '書類選考のみ',
];

// カルテ
export const SELECTABLE_CHARTS_TYPE = {
  ELECTRONIC: 'electronic',
  PAPER: 'paper',
};
export const CHARTS_LABEL = {
  [SELECTABLE_CHARTS_TYPE.ELECTRONIC]: '電子カルテ',
  [SELECTABLE_CHARTS_TYPE.PAPER]: '紙カルテ',
};

// 問い合わせ用 FORM
export const INQUIRY_FORM_URL = 'https://forms.gle/HhcHnK6rWTa9sVbL7'; // google forms

export const messageStatusTitles: { [key in MessageStatus]: string } = {
  CLOSE: '掲載終了',
  DRAFT: '下書き',
  PUBLISHED: '掲載中',
  WAITING: '掲載前',
};

export const messageStatusValues: MessageStatus[] = [
  'DRAFT',
  'WAITING',
  'PUBLISHED',
  'CLOSE',
];

export const messageStatusList: { id: MessageStatus; name: string }[] =
  messageStatusValues.map((v) => ({ id: v, name: messageStatusTitles[v] }));

export const messageTargetTitles: { [key in MessageTarget]: string } = {
  common: '共通',
  demand: 'ドクター向け',
  supply: 'クリニック向け',
};

export const notRequireClinicalDepartmentName = '専門科問わず';

// 年月日
export const dayOptions = Array.from({ length: 31 }, (val, i) => i + 1);
export const monthOptions = Array.from({ length: 12 }, (val, i) => i + 1);
const currentYear = new Date().getFullYear();
export const yearOptions = Array.from(
  { length: currentYear - 1900 + 1 },
  (val, i) => i + 1900
).reverse();

export const passwordPattern = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,24}$/;

export const MAX_FETCH_ORGANIZATION_SIZE = 50;
