import { Organization } from '@app/types/organization';

export type Catalog = {
  '@nextLink': string;
  count: number;
  total: number;
  value: Array<Product>;
};

export type GeoCoordinatesField = {
  coordinates: string[];
  type: string;
};

export type Attribute = {
  attributeId?: string;
  categoryIds: string[];
  children?: Attribute[];
  customFields?: AttributeCustomFields;
  groupName: string;
  id: string;
  items: AttributeItem[];
  name: string;
  order: number;
  type: string;
};

export interface AttributeCustomFields {
  icon?: {
    type: string;
    value: string;
  };
  keywords?: string;
}

export interface AttributeItem {
  groupName: string;
  key: string;
  value: string;
}

export type AttributeListResponse = {
  '@nextLink': string;
  count: number;
  total: number;
  value: Attribute[];
};

export type ProductAttribute = {
  attributeId: string;
  value: string;
};

export type Product = {
  additionalInformation: string;
  attributes: ProductAttribute[];
  category: Category;
  categoryId: string;
  customFields: ProductCustomFields;
  description: string;
  dimensions: string; // NOTE:未使用
  features: ProductFeature[]; // NOTE:未使用
  geoCoordinatesField?: GeoCoordinatesField; // NOTE:未使用
  id: string;
  images: ProductImage[]; // NOTE:未使用
  locations: ProductLocation[];
  name: string;
  organization: Partial<Organization>;
  organizationId: string;
  publication: ProductPublication;
  tags: string[]; // NOTE:未使用
  variantNote: string; // NOTE:未使用
  variants: ProductVariant[];
};

export type ProductOverWorkConditions =
  | '時間調整可'
  | '残業あり'
  | '残業代の追加あり';

export type ProductCustomFields = {
  access: string;
  breakTime: number;
  breakTimeNoParticular: boolean;
  calculatedDairyPay: number;
  calculatedHourlyPay: number;
  commuting: string;
  commutingCost: number;
  count: number;
  day: string;
  endTime: string;
  incentive: string;
  orderConditions: string;
  organizationName: string;
  overWorkConditions: ProductOverWorkConditions[];
  repeatDetail: string;
  repeatWeek: number[];
  selection: string;
  startTime: string;
  workAddress1: string;
  workAddress2: string;
  workPlaceInfo?: string;
  workPostalCode: string;
  workPublicHoliday: boolean;
};

export type ProductLocation = {
  code: string;
  createdAt: string;
  id: string;
  name: string;
  parent: string;
  type: SupportedLocationType;
  updatedAt: string;
};

export type ProductInput = Omit<
  Product,
  | 'category'
  | 'geoCoordinatesField'
  | 'id'
  | 'images'
  | 'locations'
  | 'organizationId'
  | 'publication'
  | 'variants'
  | 'organization'
> & {
  geoCoordinatesField?: GeoLocationPointInput; // NOTE:未使用
  imageIds: string[]; // NOTE:未使用
  locationIds: string[];
  publication: ProductPublicationInput;
  variants: ProductVariantInput[];
};

export type ProductUpdate = Partial<
  Omit<
    Product,
    | 'category'
    | 'customFields'
    | 'geoCoordinatesField'
    | 'id'
    | 'images'
    | 'locations'
    | 'organizationId'
    | 'publication'
    | 'variants'
    | 'organization'
  >
> & {
  customFields?: Partial<ProductCustomFields>;
  geoCoordinatesField?: GeoLocationPointInput; // NOTE:未使用
  imageIds?: string[]; // NOTE:未使用
  locationIds?: string[];
  publication?: Partial<ProductPublicationInput>;
  variants?: ProductVariantUpdate[];
};

export type GeoLocationPointInput = {
  coordinates: number[];
  type: 'Point';
};

export type ProductImageInput = {
  alt: string;
  base64: string;
  variantIds: Array<string>;
};

export type ProductForm = {
  additionalInformation: string;
  categoryId: string;
  description: string;
  dimensions: string;
  endPeriod: string;
  features: Array<ProductFeature>;
  images: ProductImage[];
  latitude?: number;
  locations: Array<ProductLocation>;
  longitude?: number;
  plans: Array<Plan>;
  startPeriod: string;
  status: SupportedProductPublicationStatus;
  title: string;
  variantNote: string;
};

export type ProductFeature = {
  name: string;
  value: string;
};

export type ProductImage = {
  id: string;
  url: string;
};

export type Price = {
  amount: number;
  currency: string;
  taxIncluded: boolean;
};

export type Plan = {
  id?: string;
  price: string;
  title: string;
};

export type ProductVariant = {
  description?: string;
  id: string;
  price: Price;
  sku?: 'day' | 'hour';
  title: string;
};

export type ProductVariantInput = Omit<ProductVariant, 'id' | 'title'>;

export type ProductVariantUpdate = Partial<ProductVariantInput>;

export const ProductPublicationStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
} as const;

export type SupportedProductPublicationStatus =
  (typeof ProductPublicationStatus)[keyof typeof ProductPublicationStatus];

export type ProductPublication = {
  publishedAt: string;
  publishedBy: string;
  since: string;
  status: SupportedProductPublicationStatus;
  until: string;
};
export type ProductPublicationInput = Partial<
  Omit<ProductPublication, 'publishedAt' | 'publishedBy'>
>;

export interface CategoryResponse {
  '@nextLink': string;
  count: number;
  total: number;
  value: Array<Category>;
}

export interface Category {
  children: Category[];
  description: string;
  id?: string;
  name: string;
  numberOfProducts?: number;
  parent: null | {
    uri: string;
  };
  status: 'PENDING' | 'ACCEPTED' | 'PROCESSING' | 'ACTIVE';
}
export interface SubCategory {
  description: string;
  id?: string;
  name: string;
  numberOfProducts?: number;
  status: string;
}
export type CategoryInput = Omit<Category, 'id'>;

export interface LocationListResponse {
  '@nextLink': string;
  count: number;
  total: number;
  value: Array<Location>;
}

//TODO: Refactor type to have a sub-location structure like in Category
export interface Location {
  code: string;
  createdAt: string;
  id: string;
  name: string;
  parent: string;
  type: SupportedLocationType;
  updatedAt: string;
}

export const LocationType = {
  CITY: 'city',
  PREFECTURE: 'prefecture',
  REGION: 'region',
} as const;

export type SupportedLocationType =
  (typeof LocationType)[keyof typeof LocationType];

export const ProductStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
} as const;

export type SupportedProductStatus =
  (typeof ProductStatus)[keyof typeof ProductStatus];

export const CategoryStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
} as const;

export type SupportedCategoryStatus =
  (typeof CategoryStatus)[keyof typeof CategoryStatus];

export const ProductType = {
  REGULAR_PART_TIME: 'regularPartTime',
  SPOT: 'spot',
} as const;
export type ProductTypeKey = (typeof ProductType)[keyof typeof ProductType];

export interface ProductDetailProps {
  onClose?: () => void;
  previewMode?: boolean;
  productInput?: ProductInput;
}

export interface ProductDetailState {
  id?: string;
}
export const AttributeGroupName = {
  PARA_CLINICAL_DEPARTMENT: 'paraClinicalDepartment',
  PARA_CONDITIONS: 'paraConditions',
  PARA_JOB_TYPE: 'paraJobType',
  PARA_REQUIRE_SKILL: 'paraRequireSkill',
  PRO_DOCTOR_CLINICAL_DEPARTMENT: 'clinicalDepartment',
  PRO_DOCTOR_JOB_TYPE: 'jobType',
} as const;
export type AttributeGroupNameKey =
  (typeof AttributeGroupName)[keyof typeof AttributeGroupName];

export const CategoryParentName = {
  PARAMEDICAL: 'パラメディカル＞案件区分',
  PRO_DOCTOR: '案件区分',
} as const;

export const CategoryChildrenName = {
  PARAMEDICAL_REGULAR_PART_TIME: 'パラメディカル＞定期非常勤',
  PARAMEDICAL_SPOT: 'パラメディカル＞スポット',
  PRO_DOCTOR_REGULAR_PART_TIME: '定期非常勤',
  PRO_DOCTOR_SPOT: 'スポット',
} as const;
